.input1 {
    @apply border text-sm md:text-base lg:text-base sm:text-sm rounded-md md:rounded-lg lg:rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white;

}

.label1 {
    @apply block mb-1 md:mb-2 text-sm font-medium text-white;
}

.label2 {
    @apply text-sm font-medium text-gray-200 mr-1;
}

.fwib {
    @apply w-full text-white !bg-indigo-600 hover:!bg-indigo-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-indigo-800 transition-all disabled:!bg-gray-500 flex items-center justify-center;
}

.cfc {
    @apply flex flex-col space-y-2 w-full md:w-[45%];
}

.lfc {
    @apply flex flex-row items-center justify-between w-full md:w-[320px];
}

.fc2 {
    @apply flex flex-row items-center justify-between w-full md:w-[45%];
}
.error {
    @apply text-red-500 text-sm md:text-base ;
}