@tailwind base;
@tailwind components;
@import './styles/form.css';
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
  scroll-behavior: smooth;
}


a {
  color: inherit;
  text-decoration: none;
}


.react-share__ShareButton{
  display:flex !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:disabled {
  cursor: not-allowed;
}


iframe,
embed {
  border: none;
  overflow: hidden;
  overflow-y: hidden;

}


::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

iframe::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

embed::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}